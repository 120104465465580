import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import ReactGA from "react-ga4";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function App() {
  useEffect(() => {
    ReactGA.initialize("G-FFECZW8KXE");
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("AllyTopic | Simpler is better");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} /> 
          </Routes>
        </ScrollToTop>
      </Router>
      <div className="App">
            <TawkMessengerReact
                propertyId="658df33507843602b8065ba8"
                widgetId="1hip7s20d"/>
        </div>
    </>
  );
}


export default App;
